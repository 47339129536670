<template>
  <book-cover ref="BookCover">
    <v-list-item-title class="content_card_title text-center mb-3">
      <b>{{ $t("cardTitle.assets") }}</b>
    </v-list-item-title>
    <div style="text-align:center" v-if="loading">
      <v-progress-circular indeterminate color="#6166f5"></v-progress-circular>
    </div>
    <div class="info_layout" v-else>
      <h2 class="text-center">{{ asset.assetName }}</h2>
      <v-row class="name__detail mt-1 green--text">
        <h3 class="mb-1">{{ $t("asset.instock") }}: {{ asset.amount }}</h3>
      </v-row>
      <v-row v-if="showQr" class="avatar__detail mt-1">
        <vue-qr :text="asset.assetQrUri" :size="200"></vue-qr>
      </v-row>
      <v-row class="avatar__detail mt-3">
        <v-btn
          small
          @click="showQr = !showQr"
          elevation="0"
          color="green"
          outlined
          >{{ showQr ? $t("asset.hideQr") : $t("asset.showQr") }}</v-btn
        >
      </v-row>
      <v-list-item class="pr-2 pl-2 mb-5 mt-5">
        <v-divider></v-divider>
      </v-list-item>
      <div class="pl-3 pr-3">
        <p>
          <v-icon class="mr-2" color="black">mdi-clipboard-file-outline</v-icon>
          <b>{{ $t("asset.type") }}:</b>
          {{ asset.typeName }}
        </p>
        <p>
          <v-icon class="mr-2" color="black">mdi-file-code-outline</v-icon>
          <b>{{ $t("asset.assetCode") }}:</b>
          {{ asset.assetCode }}
        </p>
        <p>
          <v-icon class="mr-2" color="black">mdi-cash</v-icon>
          <b>{{ $t("asset.price") }}:</b>
          <b class="green--text ml-1">{{ asset.price }}</b>
        </p>
        <div>
          <v-icon class="mr-2" color="black">mdi-clipboard-text-outline</v-icon>
          <b>{{ $t("asset.description") }}:</b>
          <div
            v-html="asset.description"
            class="mt-3 mb-3 editor_result_content"
          ></div>
        </div>
      </div>
    </div>
  </book-cover>
</template>

<script>
import VueQr from "vue-qr";
import BookCover from "@/components/BookCover.vue";
import { convertPriceString } from "@/plugins/helpers";
import { AssetType } from "@/plugins/contants";

export default {
  components: { BookCover, VueQr },
  props: ["assetID"],
  watch: {
    assetID(id) {
      this.render(id);
    },
  },
  data() {
    return {
      asset: {},
      loading: true,
      showQr: false,
    };
  },
  mounted() {
    this.render(this.assetID);
  },
  methods: {
    async render(id) {
      if (!id) return;
      this.$refs.BookCover.showContent();
      this.loading = true;
      var asset = await this.$httpClient.get(`/owner/assets/${id}`);
      if (asset.error) {
        return this.showError("Cannot get asset info!");
      }
      asset.price = asset.price
        ? convertPriceString(asset.price, true)
        : this.$t("common.notprovide");
      asset.typeName =
        asset.type == AssetType.Consumable
          ? this.$t("asset.consumable")
          : this.$t("asset.asset");
      asset.assetCode = asset.assetCode || this.$t("common.notprovide");
      this.asset = asset;
      this.loading = false;
    },
  },
};
</script>
